import { useNuxtApp } from '#app'
import { useMutation } from '@tanstack/vue-query'
import ToastAction from '~/components/toast/ToastAction.vue'
import type { MaybeRefDeep } from '@tanstack/vue-query/src/types'
import type { MutationObserverOptions } from '@tanstack/query-core'
import type { QueryClient } from '@tanstack/vue-query/src/queryClient'

interface ToastMessages {
  pending?: string
  success?: string
  error?: string
}

// Define the mutation context type
interface MutationContext {
  toastPromise?: Promise<any>
}

export function useMutationWithToast<TData, TVariables, TContext = unknown>(
  mutationOptions: MaybeRefDeep<MutationObserverOptions>,
  queryClient?: QueryClient,
  toastMessages: ToastMessages = {},
) {
  const { $toast } = useNuxtApp()

  const toastId = ref()

  return useMutation<TData, unknown, TVariables, MutationContext>(
    {
      ...mutationOptions,
      onMutate: async (variables): Promise<MutationContext | undefined> => {
        // Show a pending toast notification with a customizable message
        toastId.value = Math.random()
        $toast.loading(ToastAction, {
          toastId: toastId.value,
          data: {
            message: toastMessages.pending || 'Action is pending...',
          },
          autoClose: false,
          closeOnClick: false,
          closeButton: false,
          type: 'loading',
          isLoading: true,
        })

        // Call the user's onMutate if it exists
        if (mutationOptions?.onMutate) {
          await mutationOptions.onMutate(variables)
        }

        return
      },
      onSuccess: (data, variables, context) => {
        $toast.update(toastId.value, {
          toastId: toastId.value,
          render: ToastAction,
          data: {
            message: toastMessages.success || 'Action successful!',
          },
          autoClose: true,
          closeOnClick: true,
          closeButton: false,
          type: 'success',
          isLoading: false,
        })

        // Call the user's onSuccess if it exists
        if (mutationOptions?.onSuccess) {
          mutationOptions.onSuccess(data, variables, context)
        }
        toastId.value = undefined
      },
      onError: (error, variables, context) => {
        // Todo: refactor and make optional?
        let errorMessage = ''
        if (error.status === 400 && error.data) {
          for (const key in error.data) {
            errorMessage += error.data[key].join(' ')
          }
        }

        $toast.update(toastId.value, {
          toastId: toastId.value,
          render: ToastAction,
          data: {
            message: toastMessages.error || 'Action failed!',
            details: errorMessage !== '' ? errorMessage : null,
          },
          autoClose: true,
          closeOnClick: true,
          closeButton: false,
          type: 'error',
          isLoading: false,
        })
        // Call the user's onError if it exists
        if (mutationOptions?.onError) {
          mutationOptions.onError(error, variables, context)
        }
        toastId.value = undefined
      },
    },
    queryClient,
  )
}
