<script setup lang="ts">
  import type { ToastOptions } from 'vue3-toastify'
  const props = defineProps<{
    closeToast: (e?: MouseEvent) => void
    toastProps: ToastOptions<{
      message: string
      details: string
      action?: {
        label: string
        click: () => Promise<void>
      }
    }>
  }>()
</script>

<template>
  <div class="flex flex-row w-full justify-between">
    <div class="px-6 py-5 whitespace-normal">
      {{ props.toastProps.data?.message }}
      <p v-if="props.toastProps.data?.details">
        {{ props.toastProps.data?.details }}
      </p>
    </div>
    <div class="flex flex-col w-20 items-stretch divide-y border-l">
      <button
        v-if="props.toastProps.data?.action"
        class="h-full px-4 py-2 hover:bg-gray-500/20 text-sm whitespace-normal"
        @click="
          async () => {
            props.toastProps.data?.action?.click()
            props.closeToast()
          }
        "
      >
        {{ props.toastProps.data.action?.label ?? 'Action' }}
      </button>
      <button
        class="h-full px-4 py-2 hover:bg-gray-500/20 text-sm whitespace-normal"
        @click="props.closeToast"
      >
        Dismiss
      </button>
    </div>
  </div>
</template>

<style scoped></style>
